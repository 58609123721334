
import { defineStore } from 'pinia'
import { CreditorProfile } from '~/types'

export const usePartnersStore = defineStore('partnersStore', {
  state: () => ({
    partners: [
      { name: "Casas Bahia", alias: "casas-bahia", id: 3, chave: "casas_bahia" },
      { name: "Digio", alias: "digio", id: 1, chave: "digio" },
      { name: "Ponto", alias: "ponto", id: 2, chave: "ponto" },
    ] as Array<CreditorProfile>
  }),

  getters: {
    getPartners: (state) => state.partners,
  },
})

