<script setup lang="ts">
import { NuxtError } from "nuxt/app";
import * as Sentry from '@sentry/vue';

const route = useRoute();
const props = defineProps({
  error: Object as () => NuxtError
})

if (props?.error) {
  Sentry.withScope(function (scope) {
    scope.setLevel("error");
    scope.setFingerprint([route.path]);

    Sentry.captureException(new Error(props?.error?.message, props?.error));
  });
}
</script>

<template>
  <TheHeader></TheHeader>
  <div class="container">
    <TheErrorComponent :statusCode="404" />
  </div>
  <TheFooter></TheFooter>
</template>

<style lang="scss" scoped>
.container {
  width: 100%;
  flex-grow: 1;
}
</style>