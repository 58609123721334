<template>
  <div class="d-flex align-center flex-column main-container">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup lang="ts">
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Me Lembre` : 'Me Lembre';
  },
  htmlAttrs: {
    lang: 'pt-BR'
  }
})
useSeoMeta({
  title: 'MeLembre: serviço de comunicação entre empresas e pessoas. ',
  description: 'O MeLembre é um serviço de comunicação entre empresas e pessoas. Simplificamos e aprimoramos o relacionamento com seu público.',
  // keywords: 'palavra-chave1, palavra-chave2, palavra-chave3',
  robots: 'index, follow',
  viewport: 'width=device-width, initial-scale=1.0',
  author: 'QuiteJá',
  ogTitle: 'MeLembre - Somos seu lembrete amigável!',
  ogDescription: 'O MeLembre é um serviço de comunicação entre empresas e pessoas. Simplificamos e aprimoramos o relacionamento com seu público.',
  ogLocale: 'pt-BR',

  ogImage: 'https://example.com/image.png',
  twitterCard: 'summary_large_image',
})
</script>
<style lang="scss" scoped>
.main-container {
  height: 100vh;
}
</style>