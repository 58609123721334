import { default as _400bZQ9wmyZFZMeta } from "/usr/src/nuxt-app/pages/400.vue?macro=true";
import { default as _500G6FYxrlp1HMeta } from "/usr/src/nuxt-app/pages/500.vue?macro=true";
import { default as contatoAP0kHrgfpQMeta } from "/usr/src/nuxt-app/pages/contato.vue?macro=true";
import { default as casas_45bahiahbeP4CZA8QMeta } from "/usr/src/nuxt-app/pages/credor/casas-bahia.vue?macro=true";
import { default as digiornOeF7QAkzMeta } from "/usr/src/nuxt-app/pages/credor/digio.vue?macro=true";
import { default as pontojVk6rE6U5uMeta } from "/usr/src/nuxt-app/pages/credor/ponto.vue?macro=true";
import { default as empresazwGZLR4CuVMeta } from "/usr/src/nuxt-app/pages/empresa.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as infoXjBPExDUR0Meta } from "/usr/src/nuxt-app/pages/info.vue?macro=true";
export default [
  {
    name: _400bZQ9wmyZFZMeta?.name ?? "400",
    path: _400bZQ9wmyZFZMeta?.path ?? "/400",
    meta: _400bZQ9wmyZFZMeta || {},
    alias: _400bZQ9wmyZFZMeta?.alias || [],
    redirect: _400bZQ9wmyZFZMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/400.vue").then(m => m.default || m)
  },
  {
    name: _500G6FYxrlp1HMeta?.name ?? "500",
    path: _500G6FYxrlp1HMeta?.path ?? "/500",
    meta: _500G6FYxrlp1HMeta || {},
    alias: _500G6FYxrlp1HMeta?.alias || [],
    redirect: _500G6FYxrlp1HMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/500.vue").then(m => m.default || m)
  },
  {
    name: contatoAP0kHrgfpQMeta?.name ?? "contato",
    path: contatoAP0kHrgfpQMeta?.path ?? "/contato",
    meta: contatoAP0kHrgfpQMeta || {},
    alias: contatoAP0kHrgfpQMeta?.alias || [],
    redirect: contatoAP0kHrgfpQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: casas_45bahiahbeP4CZA8QMeta?.name ?? "credor-casas-bahia",
    path: casas_45bahiahbeP4CZA8QMeta?.path ?? "/credor/casas-bahia",
    meta: casas_45bahiahbeP4CZA8QMeta || {},
    alias: casas_45bahiahbeP4CZA8QMeta?.alias || [],
    redirect: casas_45bahiahbeP4CZA8QMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/credor/casas-bahia.vue").then(m => m.default || m)
  },
  {
    name: digiornOeF7QAkzMeta?.name ?? "credor-digio",
    path: digiornOeF7QAkzMeta?.path ?? "/credor/digio",
    meta: digiornOeF7QAkzMeta || {},
    alias: digiornOeF7QAkzMeta?.alias || [],
    redirect: digiornOeF7QAkzMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/credor/digio.vue").then(m => m.default || m)
  },
  {
    name: pontojVk6rE6U5uMeta?.name ?? "credor-ponto",
    path: pontojVk6rE6U5uMeta?.path ?? "/credor/ponto",
    meta: pontojVk6rE6U5uMeta || {},
    alias: pontojVk6rE6U5uMeta?.alias || [],
    redirect: pontojVk6rE6U5uMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/credor/ponto.vue").then(m => m.default || m)
  },
  {
    name: empresazwGZLR4CuVMeta?.name ?? "empresa",
    path: empresazwGZLR4CuVMeta?.path ?? "/empresa",
    meta: empresazwGZLR4CuVMeta || {},
    alias: empresazwGZLR4CuVMeta?.alias || [],
    redirect: empresazwGZLR4CuVMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/empresa.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: infoXjBPExDUR0Meta?.name ?? "info",
    path: infoXjBPExDUR0Meta?.path ?? "/info",
    meta: infoXjBPExDUR0Meta || {},
    alias: infoXjBPExDUR0Meta?.alias || [],
    redirect: infoXjBPExDUR0Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/info.vue").then(m => m.default || m)
  }
]