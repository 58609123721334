<template>
  <header role="header" :class="isOnTop ? '' : 'header-shadow'">
    <div class="content">
      <slot name="logos">
        <NuxtLink to="/" class="d-flex">
          <img class="logo" src="@/assets/logos/meLembreLogo.svg" alt="Logo Me lembre" loading="lazy" />
        </NuxtLink>
      </slot>
      <v-menu transition="slide-y-transition">
        <template #activator="{ props }">
          <v-btn class="button-menu rounded" variant="text" min-width="24" max-width="24" id="menu-button" aria-label="Menu Buttom">
            <TheIcon v-bind="props" icon="mdi-menu" :size="28" type="" />
          </v-btn>
        </template>

        <v-list class="header-list">
          <v-list-item>
            <v-list-item-title>
              <NuxtLink to="/">Home</NuxtLink>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <NuxtLink to="/#about-company">Quem somos</NuxtLink>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <NuxtLink to="/empresa">Para sua empresa</NuxtLink>
            </v-list-item-title>
          </v-list-item>
          <v-list-group value="Parceiros" @click="e => e.stopPropagation()">
            <template v-slot:activator="{ props }">
              <v-list-item class="header-list-title" v-bind="props">Parceiros</v-list-item>
            </template>

            <v-list-item v-for="(creditor, index) in creditors" :key="index">
              <NuxtLink :to="`/credor/${creditor.alias}`">{{ creditor.name }}</NuxtLink>
            </v-list-item>
          </v-list-group>
          <v-list-item>
            <v-list-item-title>
              <NuxtLink to="/contato">Ajuda</NuxtLink>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="navigation">
        <NuxtLink to="/">Home</NuxtLink>
        <NuxtLink to="/#about-company">Quem somos</NuxtLink>
        <NuxtLink to="/empresa">Para sua empresa</NuxtLink>
        <v-menu transition="slide-y-transition" location="bottom">
          <template #activator="{ isActive, props }">
            <span class="header-list-title d-flex" v-bind="props" append="mdi-menu">
              Parceiros
              <TheIcon v-bind="props" class="ml-2" :icon="`mdi-${isActive ? 'chevron-up' : 'chevron-down'}`" :size="20"
                type="" />
            </span>
          </template>
          <v-list class="header-list" style="transform: translateY(16px);">
            <v-list-item v-for="(creditor, index) in creditors" :key="index">
              <NuxtLink :to="`/credor/${creditor.alias}`">{{ creditor.name }}</NuxtLink>
            </v-list-item>
          </v-list>
        </v-menu>
        <NuxtLink to="/contato">Ajuda</NuxtLink>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { VBtn, VMenu, VList, VListItem, VListItemTitle, VListGroup } from 'vuetify/components';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { usePartnersStore } from '@/stores/home/partners';

const creditor = ref<string>("");

const store = usePartnersStore();
const creditors = store.getPartners.filter(partner => partner.alias != creditor.value);

const isOnTop = ref<Boolean>(true);

const toggleHeaderShadow = () => {
  if (window.scrollY >= 70) {
    isOnTop.value = false;
  } else {
    isOnTop.value = true;
  }
}

onMounted(() => {
  window.addEventListener('scroll', toggleHeaderShadow, {passive: true});
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', toggleHeaderShadow);
})

</script>

<style lang="scss">
@import "@/styles/variables";

header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 54px;
  background: #FFFFFF;
  position: fixed;
  z-index: 999;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;

  &>* {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    width: 1169px;
    max-width: 1169px;

    .logo {
      width: 79px;
    }

    .navigation {
      display: none;
    }
  }

  .selected-route {
    background-color: unset;
  }

  @media screen and (min-width: 834px) and (max-width: 959px) {
    .content {
      padding: 0px 32px 0px 30px;
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1199px) {
    .content {
      padding: 0px 33px;
    }
  }

  @media screen and (min-width: 960px) {
    height: 64px;

    .content {

      .navigation {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        column-gap: 34px;

        a {
          text-decoration: none !important;
          color: $greyDark;

          &:link,
          &:visited,
          &:active {
            color: $greyDark;
          }

          &:hover {
            color: $blue;
          }
        }

        .header-list-title:hover {
          color: $blue;
        }
      }

      .logo {
        width: 100px;
      }
    }

    .button-menu {
      display: none;
    }
  }

  @media screen and (min-width: 1200px) {
    .content {
      padding: 0px;
    }
  }
}

.header-list {
  border-radius: 13px !important;
  border: 1px solid #CFCFCF;
  box-shadow: 0px 0px 8px 0px #C4C4C4 !important;

  .v-list-item {
    margin: 0px 18px;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px dashed #B7B7B7;
    }

    &:hover {
      a {
        color: $blue;
      }
    }
  }

  .v-list-group .v-list-item:last-child {
    border-bottom: 1px dashed #B7B7B7;
  }
}

.header-list a,
.header-list-title {
  text-decoration: none;
  color: $greyDark;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  align-items: self-end;
}

.header-shadow {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
}
</style>
